@import '../../styles/variables';

/* Main Header
 * ============================== */
.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  transform: translateY(0);
  position: fixed;
}

/* Top
 * ============================== */
.header-primary__top {
  display: flex;
  justify-content: space-between;
  padding: .875rem 1.25rem;
  z-index: 1;
  width: 100%;

  @include at-query($min, $large) {
    padding: .875rem 2rem;
  }

  @include at-query($min, $wide) {}

  .headroom & {
    background-color: #fff;
  }

  .headroom--start & {
    background-color: transparent;
  }
}

.header-primary__top-blank {
  margin-right: auto;
  flex: 1;

  @include at-query($min, $large) {
    display: none;
  }
}

.header-primary__profile {
  display: flex;
}

.header-primary__profile-item {
  display: flex;
  align-items: center;
  margin-right: .5rem;

  @include at-query($min, $large) {
    margin-right: 1.5rem;
  }

  @include at-query($min, $wide) {
    margin-right: 2.75rem;
  }
}

.header-primary__profile-pic {
  max-width: 2rem;
  margin-right: .5rem;

  @include at-query($min, $large) {
    max-width: 2.75rem;
  }

  @include at-query($min, $wide) {
    max-width: 4rem;
  }

  img {
    border-radius: 100%;
    width: 100%;
    border: 1px solid #979797;
  }
}

.header-primary__profile-link {
  font-size: .625rem;
  font-weight: 400;
  text-decoration: none;
  color: #000;

  &:hover {
    color: #000;
  }

  .headroom--start & {
    color: #fff;
  }

  .headroom--start.headroom-wrapper--dark & {
    color: #000;
  }

  @include at-query($min, $large) {
    font-size: .75rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.25rem;
  }
}

.header-primary__view-cart-wrapper {
  margin-left: auto;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.header-primary__view-cart {
  border: none;
  background: none;
  cursor: pointer;
  color: #000;
  display: flex;
  align-items: center;

  @include at-query($min, $large) {
    font-size: .875rem;
    padding-right: 2.5rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.25rem;
  }

  svg {
    max-width: 30px;

    @include at-query($min, $large) {
      margin-right: 10px;
    }

    @include at-query($min, $wide) {
      margin-right: 14px;
      max-width: initial;
    }
  }

  .headroom--start & {
    color: #fff;
  }

  .headroom--start.headroom-wrapper--dark & {
    color: #000;
  }
}

.header-primary__view-cart-main {
  position: relative;
}

.header-primary__view-cart-main-count {
  position: absolute;
  background: #000;
  color: #fff;
  font-weight: 500;
  border-radius: 50%;
  top: 8px;
  right: -2px;
  height: 10px;
  width: 10px;
  font-size: 8px;
  line-height: 1.5;
  text-indent: 0;
  text-align: center;

  .headroom--start & {
    background: #fff;
    color: #000;
  }

  .headroom--start.headroom-wrapper--dark & {
    background: #000;
    color: #fff;
  }

  @include at-query($min, $large) {
    right: 5px;
  }

  @include at-query($min, $wide) {
    top: -5px;
    right: 5px;
    height: 20px;
    width: 20px;
    font-size: 13px;
    line-height: 1.75;
  }
}

.header-primary__view-cart-text {
  display: none;

  @include at-query($min, $large) {
    display: block;
  }
}

.header-primary__cart-logo {
  .headroom & {
    fill: #000;
  }

  .headroom--start & {
    fill: #fff;
  }

  .headroom--start.headroom-wrapper--dark & {
    fill: #000;
  }
}