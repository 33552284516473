/* HERO
 * ============================== */
.hero {}

.hero--space-below {
  margin-bottom: 3.75rem;

  @include at-query($min, $large) {
    margin-bottom: 7.5rem
  }
}

.hero__picture {
  text-align: center;
  display: flex;
  justify-content: center;
}

.hero__picture-img {
  width: 100%;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}