/* LINE ITEMS
 * ============================== */
 .line-item {
  display: flex;
  margin-bottom: 20px;
}

.line-item__meta {
  display: flex;
  flex: 1;
}

.line-item__img {
  max-width: 5.5rem;
  margin-right: 1rem;

  img {
    width: 100%;
  }
}

.line-item__content {
}

.line-item__content-row {}

.line-item__title {
  font-size: 1rem;
  font-weight: 500;
}

.line-item__variant-title {
  font-size: .875rem;
}


.line-item__price {
  font-size: .875rem;
}

.line-item__remove {
  font-size: 20px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  margin-right: 1.25rem;
  padding: 0;
}

.line-item__quantity-container {
  padding-top: 1.5rem;
}

.line-item__option {
  width: 4.25rem;
  margin-bottom: 0;
  padding: 9px 12px;
  border-radius: 0;
  border-color: #d8d8d8;
  font-size: .75rem;

  padding-right: 30px;
  appearance: none;
  background: url(../../public/arrow_down.svg) no-repeat;
  background-position: right 12px top 50%;
  background-size: 9px;
}