/* Quotes Secondary
 * ============================== */
.quotes-secondary {
  display: flex;
  padding: 6.25rem 1.25rem 2.5rem;
  justify-content: center;
  margin-top: -2.5rem;

  @include at-query($min, $large) {
    margin-top: -4.5rem;
    padding: 7.5rem 1.25rem 4.5rem;
  }

  @include at-query($min, $wide) {
    margin-top: -9.25rem;
    padding: 15.5rem 1.25rem 9.25rem;
  }
}

.quotes-secondary__picture {
  margin-right: 1.5rem;

  @include at-query($min, $large) {
    margin-right: 2.75rem;
  }

  @include at-query($min, $wide) {
    margin-right: 5rem;
  }
}

.quotes-secondary__picture-content {
  border-radius: 50%;
  max-width: 5.75rem;

  @include at-query($min, $large) {
    max-width: 8.75rem;
  }

  @include at-query($min, $wide) {
    max-width: 15.25rem;
  }
}

.quotes-secondary__main {
  padding: 1.5rem;
  border-radius: 1.5rem;
  border: 3px solid #DDDDDD;
  background-color: #ffffff;
  position: relative;

  @include at-query($min, $large) {
    border-radius: 1.5rem;
    padding: 2.5rem;
    max-width: 21.25rem;
  }

  @include at-query($min, $wide) {
    border-radius: 2.5rem;
    padding: 3.5rem;
    max-width: 39rem;
  }

  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #dddddd;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    left: -23px;
    top: 20px;

    @include at-query($min, $large) {
      border-left: 20px solid transparent;
      border-right: 20px solid #dddddd;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      left: -43px;
      top: 30px;
    }

    @include at-query($min, $wide) {
      border-left: 35px solid transparent;
      border-right: 35px solid #dddddd;
      border-top: 35px solid transparent;
      border-bottom: 35px solid transparent;
      left: -70px;
      top: 40px;
    }
  }

  &:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #fff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    left: -19px;
    top: 20px;

    @include at-query($min, $large) {
      border-left: 20px solid transparent;
      border-right: 20px solid #fff;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      left: -39px;
      top: 30px;
    }

    @include at-query($min, $wide) {
      border-left: 35px solid transparent;
      border-right: 35px solid #fff;
      border-top: 35px solid transparent;
      border-bottom: 35px solid transparent;
      left: -66px;
      top: 40px;
    }
  }
}

.quotes-secondary__content {
  max-width: 29rem;
  font-size: .75rem;
  line-height: 1.3;
  font-style: italic;
  margin-bottom: 14px;

  @include at-query($min, $wide) {
    font-size: 1.5rem;
  }
}

.quotes-secondary__author {
  font-size: .75rem;
  font-weight: 500;

  @include at-query($min, $wide) {
    font-size: 1.5rem;
  }
}