/* App Header Quaternary
 * ============================== */
 .header-quaternary {
  position: relative;
  margin-bottom: 2.5rem;

  @include at-query($min, $large) {
    margin-bottom: 4.5rem;
  }
}

.header-quaternary__nav {
  width: 100%;
  list-style: none;
}

.header-quaternary__title {
  padding: 0 1.25rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include at-query($min, $large) {
    padding: 2rem 1.25rem 2.5rem;
  }

  @include at-query($min, $wide) {
    padding: 3.75rem 1.25rem 2.5rem;
  }
}

.header-quaternary__title-main {
  display: inline-block;
  margin-bottom: 2rem;

  @include at-query($min, $large) {
    margin-bottom: 2rem;
  }

  @include at-query($min, $wide) {
    margin-bottom: 3.5rem;
  }
}

.header-quaternary__title-content {
  font-family: Poppins;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 0;
  position: relative;
  z-index: 1;

  @include at-query($min, $large) {
    font-size: 2rem;
  }

  @include at-query($min, $wide) {
    font-size: 3.25rem;
  }
}

.header-quaternary__title-cta {
  display: inline-block;
  padding: 1rem 1.75rem;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;

  &:hover {
    color: #fff
  }

  @include at-query($min, $large) {
    font-size: .75rem;
    padding: .75rem 1rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.5rem;
    padding: 1rem 1.75rem;
  }
}

.header-quaternary__photos {
  display: flex;
  padding: 0 .25rem;
  justify-content: center;

  @include at-query($min, $large) {
    padding: 0 5.75rem;
  }
}

.header-quaternary__photo {
  padding: 0 .5rem;

  @include at-query($min, $wide) {
    padding: 0 1rem;
  }
}

.header-quaternary__photo--middle {
  padding-top: 1.25rem;

  @include at-query($min, $large) {
    padding-top: 3.5rem;
  }
}
.header-quaternary__photo-img {
  border-radius: 20px;
  width: 100%;
}