@import "../styles/_mixins";
@import "../styles/_variables";

/* INITIALIZERS + DEFAULTS
 * ============================== */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,300italic,400,400italic,500,500italic,700,700italic");

body {
  font-family: "Poppins", sans-serif;
}

img {
  display: block;
}

h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 3rem;
}

h2 {
  font-weight: 300;
  margin: 0;
  font-size: 2rem;
}

/* Animations
 * ============================== */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* BASE APP
 * ============================== */
.button {
  color: white;
  border: none;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  cursor: pointer;
  width: 100%;
  border-radius: 0;
}

.button:hover,
.button:focus {
  background-color: #222222;
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

button,
select {
  &:hover,
  &:focus {
    outline: 0;
  }
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}

.grecaptcha-badge {
  display: none;
}

body {
  &.cart-open {
      overflow-y: hidden;
      @include at-query($min, 35rem) {
          overflow-y: auto;
      }
  }
}
