/* Shop Below
 * ============================== */
.shop-below {
  margin-bottom: 3rem;

  @include at-query($min, $large) {
    margin-bottom: 6.25rem;
  }
}

.shop-below__title {
  padding: 1.5rem 1.25rem;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: .75rem;

  @include at-query($min, $large) {
    font-size: 1.25rem;
    padding: 2rem 1.25rem;
  }

  @include at-query($min, $wide) {
    font-size: 2.25rem;
    padding: 3.25rem 1.25rem;
  }
}
