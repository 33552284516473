/* Quotes
 * ============================== */
.quotes {
  display: flex;
  flex-direction: column;
  padding: 4rem 1.5rem;
  justify-content: center;

  @include at-query($min, $large) {
    flex-direction: row;
    padding: 9rem 1.5rem;
  }
}

.quotes__picture {
  @include at-query($min, $large) {
    margin-right: 70px;
  }
}

.quotes__picture-content {
  max-width: 110px;
  border-radius: 50%;
  margin: 0 auto;

  @include at-query($min, $large) {
    max-width: 9.75rem;
    margin: 0;
  }

  @include at-query($min, $wide) {
    max-width: 15.25rem;
  }
}

.quotes__main {
  padding-top: 2.5rem;
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;

  @include at-query($min, $large) {
    text-align: initial;

    // Enough spacing for the quote icon to show
    margin-left: 0px;
    margin-right: 60px;
  }
}

.quotes__content {
  font-size: .75rem;
  line-height: 1.3;
  font-style: italic;
  max-width: 18rem;
  position: relative;
  margin: 0 auto 14px;

  @include at-query($min, $large) {
    margin: initial;
    margin-bottom: 1.25rem;
    font-size: 1rem;
    max-width: 28rem;
  }

  @include at-query($min, $wide) {
    margin: initial;
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
    max-width: 28rem;
  }
}

.quotes__author {
  font-size: 1rem;
  font-weight: 500;

  @include at-query($min, $large) {
    font-size: 1rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.5rem;
  }
}

.quotes__icon-left {
  position: absolute;
  max-width: 30px;
  left: -44px;
  top: -40px;

  @include at-query($min, $wide) {
    max-width: initial;
    left: -60px;
    top: -40px;
  }
}

.quotes__icon-right {
  position: absolute;
  max-width: 30px;
  right: -38px;
  bottom: -48px;

  @include at-query($min, $wide) {
    max-width: initial;
    right: -60px;
    bottom: -20px;
  }
}