/* CART
 * ============================== */
.cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
  z-index: 1000;
  overflow-y: auto;

  @include at-query($min, 35rem) {
    width: 30rem;
  }
}

.cart--open {
  transform: translateX(0);
}

.cart__close {
  border: none;
  background: transparent;
}

.cart__title {
  font-size: 1.25rem;
}

.cart__header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}

.cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
  list-style: none;
}

.cart__footer {
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
}

.cart__checkout {
  display: block;
  width: 100%;
  background-color: #000;
  font-weight: 500;
}

.cart__info-wrapper {
  margin-bottom: 1.5rem;
}

.cart__info {
  margin-bottom: .5rem;
  display: flex;
  justify-content: space-between;
}

.cart__info-total {}

.cart__info-small {
}

.cart__info-pricing {}

.cart__pricing {
  margin-left: 5px;
  font-size: 16px;
  color: black;
}
