/* Shop Below Secondary
 * ============================== */
.shop-below-secondary {}

.shop-below-secondary__title {
  padding: 2.25rem 1rem;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  text-align: center;

  @include at-query($min, $large) {
    font-size: 1.25rem;
    padding: 2.5rem 1rem;
  }

  @include at-query($min, $wide) {
    font-size: 3rem;
    padding: 7.5rem 5rem;
  }
 }