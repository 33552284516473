/* Quotes Quaternary
 * ============================== */
.quotes-quaternary {
  display: flex;
  padding: 1.25rem;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include at-query($min, $large) {
    padding: 4.25rem 1.25rem;
    flex-direction: row;
    align-items: initial;
    text-align: initial;
  }
}

.quotes-quaternary__picture {
  @include at-query($min, $large) {
    margin-right: 2rem;
  }

  @include at-query($min, $wide) {
    margin-right: 3rem;
  }
}

.quotes-quaternary__picture-content {
  max-width: 7.75rem;
  border-radius: 50%;
  border: 2px solid #E1E1E1;
  margin: 0 0 1.5rem;

  @include at-query($min, $large) {
    margin: 1rem 0;
  }

  @include at-query($min, $wide) {
    margin: 1.5rem 0;
    max-width: 12.5rem;
  }
}

.quotes-quaternary__divider {
  display: none;
  width: 4px;
  background-color: #fff;

  @include at-query($min, $large) {
    display: block;
    margin-right: 2rem;
  }

  @include at-query($min, $wide) {
    margin-right: 3rem;
  }
}

.quotes-quaternary__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quotes-quaternary__content {
  max-width: 36.5rem;
  font-size: .75rem;
  line-height: 1.3;
  font-style: italic;
  margin-bottom: 14px;

  @include at-query($min, $large) {
    font-size: 1rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.5rem;
  }
}

.quotes-quaternary__author {
  font-size: .75rem;
  font-weight: 400;

  @include at-query($min, $large) {
    font-size: 1rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.5rem;
  }
}