/* Quotes Tertiary
 * ============================== */
.quotes-tertiary {
  padding: 3.25rem 2.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include at-query($min, $large) {
    padding: 5rem 1.25rem;
  }

  @include at-query($min, $wide) {
    padding: 7.5rem 1.25rem;
  }
}

.quotes-tertiary__picture {
  max-width: 3rem;
  margin-bottom: 2rem;

  @include at-query($min, $wide) {
    max-width: 5rem;
  }

  img {
    width: 100%;
  }
}

.quotes-tertiary__picture-content {
  border-radius: 50%;
  border: 1px solid #979797;
}

.quotes-tertiary__main {}

.quotes-tertiary__content {
  max-width: 1000px;
  font-size: 1rem;
  line-height: 1.3;
  font-style: italic;
  margin-bottom: 14px;
  text-align: center;

  @include at-query($min, $large) {
    font-size: 1.25rem;
    max-width: 37rem;
  }

  @include at-query($min, $wide) {
    font-size: 2.25rem;
    max-width: 64rem;
  }
}

.quotes-tertiary__author {
  font-size: .75rem;
  font-weight: 500;
  text-align: center;

  @include at-query($min, $large) {
    font-size: 1rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.5rem;
  }
}