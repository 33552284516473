
@import '../styles/_variables';

.clearfix {
  &:after {
    content: '';
    display: table;
    clear: both; }
  *zoom: 1;
}

@mixin clearfix() {
  &:after {
    content: '';
    display: table;
    clear: both; }
  *zoom: 1;
}

/*============================================================================
  Dependency-free breakpoint mixin
    - Based on http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
    - Usage docs: http://shopify.github.io/Timber/#sass-mixins
==============================================================================*/
$min: min-width;
$max: max-width;
@mixin at-query ($constraint_, $viewport1_, $viewport2_:null) {
 $constraint: $constraint_; $viewport1: $viewport1_; $viewport2: $viewport2_;
  @if type-of($constraint_) == number {
    $viewport1 : $constraint_; $viewport2 : $viewport1_; $constraint : null;
  }
  @if $constraint == $min {
    @media screen and ($min: $viewport1) {
      @content;
    }
  } @else if $constraint == $max {
    @media screen and ($max: $viewport1) {
      @content;
    }
  } @else {
    @media screen and ($min: $viewport1) and ($max: $viewport2) {
      @content;
    }
  }
}

/*============================================================================
  #Grid Setup
    - Based on csswizardry grid, but with floated columns, a fixed gutter size, and BEM classes
    - Breakpoints defined above, under #Breakpoint and Grid Variables
    - Note the inclusion of .grid-uniform to take care of clearfixes on evenly sized grid items
==============================================================================*/
$responsive:         true;
$mobile-first:       true;
$use-silent-classes: false;
$push:               true;
$pull:               false;
$use-markup-fix:     false !default;

/* Force clearfix on grids */
.grid,
.grid-uniform {
  @include clearfix;
}

/* Manual grid-item clearfix */
.grid-item.clear {
  clear: both;
}

$class-type: unquote(".");

@if $use-silent-classes == true {
  $class-type: unquote("%");
}

@mixin grid-media-query($media-query) {
  $breakpoint-found: false;

  @each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $declaration: nth($breakpoint, 2);

    @if $media-query == $name and $declaration {
      $breakpoint-found: true;

      @media only screen and #{$declaration} {
        @content;
      }
    }
  }

  @if $breakpoint-found == false {
    @warn "Breakpoint '#{$media-query}' does not exist";
  }
}


/*============================================================================
  Drop relative positioning into silent classes which can't take advantage of
  the `[class*="push--"]` and `[class*="pull--"]` selectors.
==============================================================================*/
@mixin silent-relative() {
  @if $use-silent-classes == true {
    position:relative;
  }
}

/*============================================================================
  Grid Setup
    1. Allow the grid system to be used on lists.
    2. Remove any margins and paddings that might affect the grid system.
    3. Apply a negative `margin-left` to negate the columns' gutters.
==============================================================================*/
#{$class-type}grid,
#{$class-type}grid-uniform {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -$gridGutter;
  @if not $use-markup-fix {
      letter-spacing:-0.31em;
  }
}

@if not $use-markup-fix {
  /* Opera hack */
  .opera:-o-prefocus,
  #{$class-type}grid {
      word-spacing:-0.43em;
  }
}

#{$class-type}grid-item {
  box-sizing: border-box;
  display:inline-block;
  min-height: 1px;
  padding-left: $gridGutter;
  vertical-align: top;
  @if $mobile-first == true {
    width: 100%;
  }

  @if not $use-markup-fix {
      letter-spacing:normal;
      word-spacing:normal;
  }
}

/*============================================================================
  Reversed grids allow you to structure your source in the opposite
  order to how your rendered layout will appear.
==============================================================================*/
#{$class-type}grid--rev {
  @extend #{$class-type}grid;
  direction: rtl;
  text-align: left;

  > #{$class-type}grid-item {
    direction: ltr;
    text-align: left;
    float: right;
  }
}

/* Gutterless grids have all the properties of regular grids, minus any spacing. */
#{$class-type}grid--full {
  @extend #{$class-type}grid;
  margin-left: 0;

  > #{$class-type}grid-item {
    padding-left: 0;
  }
}

/**
 * Centered grids align grid items centrally without needing to use push or pull
 * classes. Extends `.grid`.
 */
#{$class-type}grid--center {
    text-align:center;

    > #{$class-type}grid-item {
        text-align:left;
    }
}

/**
 * Align grid cells vertically (`.grid--middle` or `.grid--bottom`). Extends
 * `.grid`.
 */
#{$class-type}grid--middle {

    > #{$class-type}grid-item {
        vertical-align:middle;
    }
}

/**
 * Align grid cells vertically (`.grid--middle` or `.grid--bottom`). Extends
 * `.grid`.
 */
#{$class-type}large--grid--middle {

    > #{$class-type}grid-item {
        @include at-query ($min, $large) {
          vertical-align:middle;
        }
    }
}

/**
 * Align grid cells vertically (`.grid--middle` or `.grid--bottom`). Extends
 * `.grid`.
 */
#{$class-type}wide--grid--middle {

    > #{$class-type}grid-item {
        @include at-query ($min, $wide) {
          vertical-align:middle;
        }
    }
}


/**
 * Create grids with narrower gutters. Extends `.grid`.
 */
#{$class-type}grid--narrow {
    margin-left:-($gutter / 2);

    > #{$class-type}grid-item {
        padding-left:$gutter / 2;
    }
}


/**
 * Create grids with wider gutters. Extends `.grid`.
 */
#{$class-type}grid--wide {
    margin-left:-($gutter * 2);

    > #{$class-type}grid-item {
        padding-left:$gutter * 2;
    }
}

/*============================================================================
  WIDTHS
    - Create width classes, prefixed by the specified namespace.
==============================================================================*/
@mixin device-type($namespace:"") {
  /** Whole */
  #{$class-type}#{$namespace}one-whole       { width: 100%; }

  /* Halves */
  #{$class-type}#{$namespace}one-half        { width: 50%; }

  /* Thirds */
  #{$class-type}#{$namespace}one-third       { width: 33.333%; }
  #{$class-type}#{$namespace}two-thirds      { width: 66.666%; }

  /* Quarters */
  #{$class-type}#{$namespace}one-quarter     { width: 25%; }
  #{$class-type}#{$namespace}two-quarters    { width: 50%; }
  #{$class-type}#{$namespace}three-quarters  { width: 75%; }

  /* Fifths */
  #{$class-type}#{$namespace}one-fifth       { width: 20%; }
  #{$class-type}#{$namespace}two-fifths      { width: 40%; }
  #{$class-type}#{$namespace}three-fifths    { width: 60%; }
  #{$class-type}#{$namespace}four-fifths     { width: 80%; }

  /* Sixths */
  #{$class-type}#{$namespace}one-sixth       { width: 16.666%; }
  #{$class-type}#{$namespace}two-sixths      { width: 33.333%; }
  #{$class-type}#{$namespace}three-sixths    { width: 50%; }
  #{$class-type}#{$namespace}four-sixths     { width: 66.666%; }
  #{$class-type}#{$namespace}five-sixths     { width: 83.333%; }

  /* Eighths */
  #{$class-type}#{$namespace}one-eighth      { width: 12.5%; }
  #{$class-type}#{$namespace}two-eighths     { width: 25%; }
  #{$class-type}#{$namespace}three-eighths   { width: 37.5%; }
  #{$class-type}#{$namespace}four-eighths    { width: 50%; }
  #{$class-type}#{$namespace}five-eighths    { width: 62.5%; }
  #{$class-type}#{$namespace}six-eighths     { width: 75%; }
  #{$class-type}#{$namespace}seven-eighths   { width: 87.5%; }

  /* Tenths */
  #{$class-type}#{$namespace}one-tenth       { width: 10%; }
  #{$class-type}#{$namespace}two-tenths      { width: 20%; }
  #{$class-type}#{$namespace}three-tenths    { width: 30%; }
  #{$class-type}#{$namespace}four-tenths     { width: 40%; }
  #{$class-type}#{$namespace}five-tenths     { width: 50%; }
  #{$class-type}#{$namespace}six-tenths      { width: 60%; }
  #{$class-type}#{$namespace}seven-tenths    { width: 70%; }
  #{$class-type}#{$namespace}eight-tenths    { width: 80%; }
  #{$class-type}#{$namespace}nine-tenths     { width: 90%; }

  /* Twelfths */
  #{$class-type}#{$namespace}one-twelfth     { width: 8.333%; }
  #{$class-type}#{$namespace}two-twelfths    { width: 16.666%; }
  #{$class-type}#{$namespace}three-twelfths  { width: 25%; }
  #{$class-type}#{$namespace}four-twelfths   { width: 33.333%; }
  #{$class-type}#{$namespace}five-twelfths   { width: 41.666% }
  #{$class-type}#{$namespace}six-twelfths    { width: 50%; }
  #{$class-type}#{$namespace}seven-twelfths  { width: 58.333%; }
  #{$class-type}#{$namespace}eight-twelfths  { width: 66.666%; }
  #{$class-type}#{$namespace}nine-twelfths   { width: 75%; }
  #{$class-type}#{$namespace}ten-twelfths    { width: 83.333%; }
  #{$class-type}#{$namespace}eleven-twelfths { width: 91.666%; }
}

/*================ Clearfix helper on uniform grids ================*/
/* In .grid-uniform, use .large and .wide */
/* Not .large-up and .wide */

@mixin clearfix-helper($namespace:"") {
  .grid-uniform {
    #{$class-type}#{$namespace}one-half:nth-child(2n+1),
    #{$class-type}#{$namespace}one-third:nth-child(3n+1),
    #{$class-type}#{$namespace}one-quarter:nth-child(4n+1),
    #{$class-type}#{$namespace}one-fifth:nth-child(5n+1),
    #{$class-type}#{$namespace}one-sixth:nth-child(6n+1),
    #{$class-type}#{$namespace}two-sixths:nth-child(3n+1),
    #{$class-type}#{$namespace}three-sixths:nth-child(2n+1),
    #{$class-type}#{$namespace}two-eighths:nth-child(4n+1),
    #{$class-type}#{$namespace}four-eighths:nth-child(2n+1),
    #{$class-type}#{$namespace}five-tenths:nth-child(2n+1),
    #{$class-type}#{$namespace}one-twelfth:nth-child(12n+1),
    #{$class-type}#{$namespace}two-twelfths:nth-child(6n+1),
    #{$class-type}#{$namespace}three-twelfths:nth-child(4n+1),
    #{$class-type}#{$namespace}four-twelfths:nth-child(3n+1),
    #{$class-type}#{$namespace}six-twelfths:nth-child(2n+1)    { clear: both; }
  }
}

/*============================================================================
  PUSH
    - Push classes, to move grid items over to the right by certain amounts
==============================================================================*/
@mixin push-setup($namespace: "") {
  /* Whole */
  #{$class-type}push--#{$namespace}one-whole       { left: 100%; @include silent-relative(); }

  /* Halves */
  #{$class-type}push--#{$namespace}one-half        { left: 50%; @include silent-relative(); }

  /* Thirds */
  #{$class-type}push--#{$namespace}one-third       { left: 33.333%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}two-thirds      { left: 66.666%; @include silent-relative(); }

  /* Quarters */
  #{$class-type}push--#{$namespace}one-quarter     { left: 25%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}two-quarters    { left: 50%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}three-quarters  { left: 75%; @include silent-relative(); }

  /* Fifths */
  #{$class-type}push--#{$namespace}one-fifth       { left: 20%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}two-fifths      { left: 40%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}three-fifths    { left: 60%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}four-fifths     { left: 80%; @include silent-relative(); }

  /* Sixths */
  #{$class-type}push--#{$namespace}one-sixth       { left: 16.666%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}two-sixths      { left: 33.333%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}three-sixths    { left: 50%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}four-sixths     { left: 66.666%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}five-sixths     { left: 83.333%; @include silent-relative(); }

  /* Eighths */
  #{$class-type}push--#{$namespace}one-eighth      { left: 12.5%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}two-eighths     { left: 25%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}three-eighths   { left: 37.5%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}four-eighths    { left: 50%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}five-eighths    { left: 62.5%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}six-eighths     { left: 75%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}seven-eighths   { left: 87.5%; @include silent-relative(); }

  /* Tenths */
  #{$class-type}push--#{$namespace}one-tenth       { left: 10%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}two-tenths      { left: 20%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}three-tenths    { left: 30%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}four-tenths     { left: 40%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}five-tenths     { left: 50%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}six-tenths      { left: 60%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}seven-tenths    { left: 70%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}eight-tenths    { left: 80%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}nine-tenths     { left: 90%; @include silent-relative(); }

  /* Twelfths */
  #{$class-type}push--#{$namespace}one-twelfth     { left: 8.333%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}two-twelfths    { left: 16.666%; @include silent-relative();  }
  #{$class-type}push--#{$namespace}three-twelfths  { left: 25%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}four-twelfths   { left: 33.333%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}five-twelfths   { left: 41.666%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}six-twelfths    { left: 50%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}seven-twelfths  { left: 58.333%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}eight-twelfths  { left: 66.666%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}nine-twelfths   { left: 75%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}ten-twelfths    { left: 83.333%; @include silent-relative(); }
  #{$class-type}push--#{$namespace}eleven-twelfths { left: 91.666%; @include silent-relative(); }
}

@if $push == true {
  [class*="push--"]{ position:relative; }

  @include push-setup();

  @if $responsive == true {
    @each $name in $breakpoint-has-push {
      @include grid-media-query($name) {
        @include push-setup('#{$name}--');
      }
    }
  }
}

/*============================================================================
  PULL
    - Pull classes, to move grid items back to the left by certain amounts
==============================================================================*/
@mixin pull-setup($namespace: "") {
  /* Whole */
  #{$class-type}pull--#{$namespace}one-whole       { right: 100%; @include silent-relative(); }

  /* Halves */
  #{$class-type}pull--#{$namespace}one-half        { right: 50%; @include silent-relative(); }

  /* Thirds */
  #{$class-type}pull--#{$namespace}one-third       { right: 33.333%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}two-thirds      { right: 66.666%; @include silent-relative(); }

  /* Quarters */
  #{$class-type}pull--#{$namespace}one-quarter     { right: 25%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}two-quarters    { right: 50%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}three-quarters  { right: 75%; @include silent-relative(); }

  /* Fifths */
  #{$class-type}pull--#{$namespace}one-fifth       { right: 20%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}two-fifths      { right: 40%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}three-fifths    { right: 60%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}four-fifths     { right: 80%; @include silent-relative(); }

  /* Sixths */
  #{$class-type}pull--#{$namespace}one-sixth       { right: 16.666%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}two-sixths      { right: 33.333%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}three-sixths    { right: 50%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}four-sixths     { right: 66.666%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}five-sixths     { right: 83.333%; @include silent-relative(); }

  /* Eighths */
  #{$class-type}pull--#{$namespace}one-eighth      { right: 12.5%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}two-eighths     { right: 25%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}three-eighths   { right: 37.5%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}four-eighths    { right: 50%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}five-eighths    { right: 62.5%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}six-eighths     { right: 75%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}seven-eighths   { right: 87.5%; @include silent-relative(); }

  /* Tenths */
  #{$class-type}pull--#{$namespace}one-tenth       { right: 10%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}two-tenths      { right: 20%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}three-tenths    { right: 30%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}four-tenths     { right: 40%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}five-tenths     { right: 50%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}six-tenths      { right: 60%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}seven-tenths    { right: 70%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}eight-tenths    { right: 80%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}nine-tenths     { right: 90%; @include silent-relative(); }

  /* Twelfths */
  #{$class-type}pull--#{$namespace}one-twelfth     { right: 8.333%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}two-twelfths    { right: 16.666%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}three-twelfths  { right: 25%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}four-twelfths   { right: 33.333%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}five-twelfths   { right: 41.666%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}six-twelfths    { right: 50%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}seven-twelfths  { right: 58.333%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}eight-twelfths  { right: 66.666%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}nine-twelfths   { right: 75%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}ten-twelfths    { right: 83.333%; @include silent-relative(); }
  #{$class-type}pull--#{$namespace}eleven-twelfths { right: 91.666%; @include silent-relative(); }
}

@if $pull == true {
  [class*="pull--"]{ position:relative; }

  @include pull-setup();

  @if $responsive == true {
    @each $name in $breakpoint-has-pull {
      @include grid-media-query($name) {
        @include pull-setup('#{$name}--');
      }
    }
  }
}

/*================ Helper show/hide classes around our breakpoints ================*/
@mixin device-helper($namespace:"") {
  #{$class-type}#{$namespace}show        { display: block!important; }
  #{$class-type}#{$namespace}hide        { display: none!important; }
  #{$class-type}#{$namespace}invisible   { visibility: hidden!important; }

  #{$class-type}#{$namespace}text-left   { text-align: left!important; }
  #{$class-type}#{$namespace}text-right  { text-align: right!important; }
  #{$class-type}#{$namespace}text-center { text-align: center!important; }

  #{$class-type}#{$namespace}left        { float: left!important; }
  #{$class-type}#{$namespace}right       { float: right!important; }

  /*================ Additional Helpers ================*/
  #{$class-type}#{$namespace}font-family-inherit{ font-family:inherit }
  #{$class-type}#{$namespace}font-size-inherit{ font-size:inherit }
  #{$class-type}#{$namespace}text-decoration-none{ text-decoration:none }

  #{$class-type}#{$namespace}bold{ font-weight: bold; }
  #{$class-type}#{$namespace}regular{ font-weight:normal }
  #{$class-type}#{$namespace}light{ font-weight:light }
  #{$class-type}#{$namespace}italic{ font-style:italic }

  #{$class-type}#{$namespace}left-align{ text-align:left }
  #{$class-type}#{$namespace}center{ text-align:center }
  #{$class-type}#{$namespace}right-align{ text-align:right }
  #{$class-type}#{$namespace}justify{ text-align:justify }

  #{$class-type}#{$namespace}nowrap{ white-space:nowrap }
  #{$class-type}#{$namespace}break-word{ word-wrap:break-word }

  #{$class-type}#{$namespace}line-height-0{ line-height: 0 }

  #{$class-type}#{$namespace}list-style-none{ list-style:none }
  #{$class-type}#{$namespace}underline{ text-decoration:underline }

  #{$class-type}#{$namespace}list-reset{
    list-style:none;
    padding-left:0;
  }

  #{$class-type}#{$namespace}inline{ display:inline }
  #{$class-type}#{$namespace}block{ display:block }
  #{$class-type}#{$namespace}inline-block{ display:inline-block }
  #{$class-type}#{$namespace}table{ display:table }
  #{$class-type}#{$namespace}table-cell{ display:table-cell }

  #{$class-type}#{$namespace}fit{ max-width:100% }

  #{$class-type}#{$namespace}ml-auto{ margin-left:auto }
  #{$class-type}#{$namespace}mr-auto{ margin-right:auto }
  #{$class-type}#{$namespace}mx-auto{ margin-left:auto; margin-right:auto; }

  #{$class-type}#{$namespace}relative{ position:relative }
  #{$class-type}#{$namespace}absolute{ position:absolute }
  #{$class-type}#{$namespace}fixed{ position:fixed }

  #{$class-type}#{$namespace}text-uppercase{ text-transform: uppercase; }
  #{$class-type}#{$namespace}full-width{ width: 100%; }
}

/*================ Our regular, non-responsive width and helper classes ================*/
@include device-type();

/*================ Our responsive classes, if we have enabled them ================*/
@if $responsive == true {
  @each $name in $breakpoint-has-widths {
    @include grid-media-query($name) {
      @include device-type('#{$name}--');
      @include device-helper('#{$name}--');
      @include clearfix-helper('#{$name}--');
    }
  }
}