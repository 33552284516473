/*============================================================================
  #Breakpoint and Grid Variables
==============================================================================*/
$viewportIncrement: 1px;

$small:  480px;
$medium: 768px;
$large:  769px;
$wide:   1100px;

$postSmall: $small + $viewportIncrement;
$preMedium: $medium - $viewportIncrement;
$preLarge:  $large - $viewportIncrement;
$preWide:   $wide - $viewportIncrement;

/*====== The following are dependencies of csswizardry grid ======*/
$breakpoints: (
  'small'       '(max-width: #{$small})',
  'medium'      '(min-width: #{$postSmall}) and (max-width: #{$medium})',
  'medium-down' '(max-width: #{$medium})',
  'medium-up'   '(min-width: #{$postSmall})',
  'large'       '(min-width: #{$large}) and (max-width: #{$wide - 1})',
  'large-down'  '(max-width: #{$wide - 1})',
  'large-up'    '(min-width: #{$large})',
  'wide'        '(min-width: #{$wide})'
);
$breakpoint-has-widths: ('small', 'medium', 'medium-up', 'medium-down', 'large', 'large-down', 'large-up', 'wide');
$breakpoint-has-push:  ('medium', 'medium-up', 'medium-down', 'large', 'large-down', 'large-up', 'wide');
$breakpoint-has-pull:  ('medium', 'medium-up', 'medium-down', 'large', 'large-down', 'large-up', 'wide');

/*============================================================================
  #General Variables
==============================================================================*/
// Helper colors
$disabledGrey: #f6f6f6;
$disabledBorder: darken($disabledGrey, 25%);
$errorRed: #d02e2e;
$errorRedBg: #fff6f6;
$successGreen: #56ad6a;
$successGreenBg: #ecfef0;

// Sizing variables
$siteWidth: 1220px;
$gutter: 20px;
$gridGutter: 20px; // can be a %, but nested grids will have smaller margins because of it
$wrapperGutter: 2rem;

// Z-index
$zindexDrawer: 10;