/* Footer
 * ============================== */
.footer {
  display: flex;
  font-size: 1rem;
  padding: 3.5rem 0;
  color: #fff;
  flex-direction: column;

  @include at-query($min, $large) {
    padding: 6.25rem 2.75rem;
    flex-direction: initial;
    font-size: 1rem;
  }

  @include at-query($min, $wide) {
    padding: 6.25rem 5.5rem;
    font-size: 1.25rem;
  }
}

.footer__column {
  flex: 1;
  margin-bottom: 3rem;
  text-align: center;

  @include at-query($min, $large) {
    text-align: initial;
    margin-bottom: 0;
  }
}

.footer__column--first {
  @include at-query($min, $large) {
    text-align: center;
    order: 2;
  }
}

.footer__logo {
  max-width: 10.5rem;
  margin: 0 auto;

  @include at-query($min, $large) {
    max-width: 10rem;
  }

  @include at-query($min, $wide) {
    max-width: 14.25rem;
  }
}

.footer__column--second {
  order: 1;
}

.footer__column--third {
  @include at-query($min, $large) {
    order: 3;
    display: flex;
    justify-content: flex-end;
  }
}

.footer__list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.footer__list-item {
  list-style: none;
  padding: 0;
}

.footer__link {
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
}

.footer__powered-by {}