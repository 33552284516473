/* App Header Tertiary
 * ============================== */
 .header-tertiary {
  background-color: #1A1A1A;

  padding-top: 76px;
  margin-top: -76px;

  @include at-query($min, $wide) {
    padding-top: 92px;
    margin-top: -92px;
  }
}

.header-tertiary__main {
  display: flex;
  flex-direction: column-reverse;
  padding: 0rem 1.5rem 2.25rem;

  @include at-query($min, $large) {
    padding: 2.75rem 1.5rem 11rem;
    flex-direction: initial;
    justify-content: center;
  }
}

.header-tertiary__nav {
  width: 100%;
  list-style: none;
}

.header-tertiary__image {
  @include at-query($min, $large) {
    margin-right: 3rem;
    max-width: 50%;
  }

  img {
    width: 100%;
  }
}

.header-tertiary__title {
  padding: 2.75rem 1.25rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1.5rem;

  @include at-query($min, $large) {
    padding: 3rem 0;
    text-align: initial;
    justify-content: initial;
    margin-bottom: initial;
  }
}

.header-tertiary__title-main {
  display: inline-block;
  margin-bottom: 10px;
}

.header-tertiary__title-content {
  font-family: Poppins;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  color: #fff;

  @include at-query($min, $large) {
    font-size: 2rem;
  }

  @include at-query($min, $wide) {
    font-size: 2.5rem;
  }

  @include at-query($min, 1350px) {
    font-size: 3.5rem;
  }
}

.header-tertiary__title-sub {
  color: #fff;
  font-size: .75rem;
  margin-bottom: 1.75rem;

  @include at-query($min, $large) {
    font-size: .75rem;
    margin-bottom: 1.75rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.25rem;
    margin-bottom: 2.25rem;
  }

  @include at-query($min, 1350px) {
    font-size: 1.5rem;
    margin-bottom: 3.25rem;
  }
}

.header-tertiary__title-cta {
  display: inline-block;
  padding: 1rem 1.75rem;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;

  &:hover {
    color: #fff
  }

  @include at-query($min, $large) {
    font-size: .75rem;
    padding: .75rem 1rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.25rem;
    padding: 1rem 1.50rem;
  }

  @include at-query($min, 1350px) {
    font-size: 1.5rem;
    padding: 1rem 1.75rem;
  }
}

.header-tertiary__title-bar {
  position: absolute;
  height: 12px;
  width: 100%;
}
.header-tertiary__title-bar--top {
  top: 0;
  left: 0;
}
.header-tertiary__title-bar--bottom {
  bottom: 0;
  left: 0;
}