@import '../../styles/variables';

/* Header Primary
 * ============================== */
.header-primary {
  color: white;
  min-height: 375px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -76px;

  @include at-query($min, $large) {
    min-height: 400px;
  }

  @include at-query($min, $wide) {
    min-height: 750px;
    margin-top: -92px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    background:rgba(0,0,0,0.4);
  }
}

.header-primary__background {
  position: absolute;
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.header-primary__header-container {
  display: flex;
  flex-direction: column;
}

.header-primary__nav {
  width: 100%;
  list-style: none;
}

.header-primary__title {
  text-align: center;
  z-index: 1;
}

.header-primary__title-main {
  display: inline-block;
  margin-bottom: 2.5rem;

  @include at-query($min, $large) {
    margin-bottom: 2.25rem;
  }

  @include at-query($min, $wide) {
    margin-bottom: 4.5rem;
  }
}

.header-primary__title-content {
  font-family: Poppins;
  font-weight: 700;
  letter-spacing: .2rem;
  font-size: 2rem;
  margin-bottom: 0;

  @include at-query($min, $large) {
    font-size: 1.75rem;
  }

  @include at-query($min, $wide) {
    font-size: 3.25rem;
    letter-spacing: .4rem;
  }
}

.header-primary__title-text {
  display: inline;
  word-spacing: 1.25rem;
  padding: 0 0.5rem;

  @include at-query($min, $wide) {
    word-spacing: 2rem;
  }
}

.header-primary__title-text-block {
  word-spacing: 0;
}

.header-primary__title-cta {
  display: inline-block;
  padding: 1rem 1.75rem;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;

  &:hover {
    color: #fff
  }

  @include at-query($min, $large) {
    font-size: .75rem;
    padding: .75rem 1rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.5rem;
    padding: 1rem 1.75rem;
  }
}