
/* PRODUCTS
 * ============================== */
.product-section {
  padding: 0 1.25rem;

  @include at-query($min, $large) {
    padding: 0 3.75rem;
  }
}

.product-wrapper {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include at-query($min, $large) {
    justify-content: left;
  }
}

/* PRODUCT
 * ============================== */
.product {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 3rem;
  max-width: 18rem;

  @include at-query($min, $medium) {
    margin-bottom: 4.5rem;
  }
}

.product--column {
  @include at-query($min, $large) {
    flex: 0 1 29.33%;
    max-width: initial;
  }
}

.product--row {
  @include at-query($min, $large) {
    margin-left: 0;
    margin-right: 0;
    max-width: initial;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    margin-bottom: 70px;
    align-items: center;
  }
}

.product__photo {
  .product--row & {
    @include at-query($min, $large) {
      flex: 1;
      margin-right: 80px;
    }
  }
}

.product__photo-img {
  width: 100%;
}

.product__meta {
  flex: 1;
  .product--column & {
    display: flex;
  }
}

.product__meta-inner {
  max-width: 300px;

  .product--column & {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.product__header {
  .product--column & {
    flex: 1;
  }
}

.product__title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1.75rem;
  margin-bottom: .5rem;

  .product--row & {
    @include at-query($min, $large) {
      margin-top: 0;
    }
  }
}

.product__price {
  display: block;
  font-size: 1.25rem;
  margin-top: -.25rem;
  margin-bottom: .75rem;
  .strikethrough {
    text-decoration: line-through;
    margin-right: 0.875rem;
  }
}

.product__variants {
  margin-bottom: .25rem;
}

.product__option {
  display: block;
  width: 100%;
  margin-bottom: .75rem;
  padding: 9px 12px;
  border-radius: 0;
  border-color: #d8d8d8;
  font-size: .75rem;

  padding-right: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(../../public/arrow_down.svg) no-repeat;
  background-position: right 12px top 50%;
  background-size: 9px;
}

.product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

.variantSelector-wrapper {
  display: flex;
  margin-left: -10px;
}

.variantSelector-element {
  flex: 1;
  padding-left: 10px;
}

.product__buy {
  text-align: center;

  &.is-adding {
    // Show loading icon on ajax additem.
    position: relative;
    color: transparent !important;

    &:after {
      content: '';
      color: transparent;
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -9px;
      margin-top: -9px;
      border-radius: 50%;
      border: 2px solid #fff;
      border-top-color: transparent;
      animation: spin 1s infinite linear;
    }
  }

  &:disabled {
    color: #9e9e9e !important;
    background-color: #e4e4e4 !important;
  }
}