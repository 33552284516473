/* App Header Secondary
 * ============================== */
 .header-secondary {}

.header-secondary__image {
  padding: 3rem;

  img {
    width: 100%;
  }

  @include at-query($min, $large) {
    padding: 0;
    max-width: 18.75rem;
  }

  @include at-query($min, $wide) {
    max-width: 37.5rem;
  }
}

.header-secondary__content {
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @include at-query($min, $large) {
    flex-direction: row;
  }
}

.header-secondary__title {
  text-align: center;
  margin-bottom: 2.5rem;

  @include at-query($min, $large) {
    text-align: initial;
    padding: 2rem 1.25rem;
    margin-bottom: 0;
  }

  @include at-query($min, $wide) {
    padding: 5rem 1.25rem;
  }
}

.header-secondary__title-main {
  display: inline-block;
  padding: 0px 12px;
  margin-bottom: 2rem;

  @include at-query($min, $large) {
    margin-bottom: 2.5rem;
  }

  @include at-query($min, $wide) {
    margin-bottom: 4rem;
  }
}

.header-secondary__title-content {
  font-family: Poppins;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  display: inline;

  @include at-query($min, $large) {
    font-size: 2.25rem;
  }

  @include at-query($min, $wide) {
    font-size: 3.25rem;
  }
}

.header-secondary__title-content-wrapper {
  position: relative;
}

.header-secondary__title-content-highlight {
  height: 1.25rem;
  width: 106%;
  left: -3%;
  display: block;
  bottom: 0rem;
  z-index: -1;
  position: absolute;

  @include at-query($min, $large) {
    height: 1.25rem;
  }

  @include at-query($min, $wide) {
    height: 2.25rem;
  }
}

.header-secondary__title-cta {
  display: inline-block;
  padding: 1rem 1.75rem;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;

  &:hover {
    color: #fff
  }

  @include at-query($min, $large) {
    font-size: .75rem;
    padding: .75rem 1rem;
  }

  @include at-query($min, $wide) {
    font-size: 1.5rem;
    padding: 1rem 1.75rem;
  }
}